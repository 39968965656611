<template>
  <site-header :phone="phone"></site-header>
  <gradient-delimiter height="32px" start-color="#e6e6e7" end-color="#f6f7f8"/>
  <div class="bg-[#f6f7f8]">
    <div class="container">
      <h1 class="w-2/3 mx-auto text-center pt-16">Безлимит от Тинькофф</h1>
      <div class="mx-auto text-center mt-8">Безлимитные приложения, 25 ГБ и 600 минут со скидкой для клиентов банка. Самая эффективная защита от спама.<br>Секретарь Олег ответит на пропущенные звонки</div>
      <yellow-button @click="gotoForm" class="mt-16 mx-auto block">Заказать сим-карту</yellow-button>
      <img src="@/assets/images/top.webp" alt="top" class="mt-16">

      <div class="md:grid grid-cols-3 gap-12">
        <div class="bg-white text-center rounded-3xl mt-16 p-6 pt-0">
          <div class="bg-[#9299a2] text-white text-3xl w-[56px] h-[56px] rounded-2xl mx-auto -mt-6 flex items-center justify-center numbers">1</div>
          <div class="mt-4">Закажите бесплатную доставку симки или оформите eSIM онлайн</div>
        </div>
        <div class="bg-white text-center rounded-3xl mt-16 p-6 pt-0">
          <div class="bg-[#9299a2] text-white text-3xl w-[56px] h-[56px] rounded-2xl mx-auto -mt-6 flex items-center justify-center numbers">2</div>
          <div class="mt-4">Установите приложение, чтобы управлять сим-картой</div>
        </div>
        <div class="bg-white text-center rounded-3xl mt-16 p-6 pt-0">
          <div class="bg-[#9299a2] text-white text-3xl w-[56px] h-[56px] rounded-2xl mx-auto -mt-6 flex items-center justify-center numbers">3</div>
          <div class="mt-4">Настройте тариф, который будет после пробного периода</div>
        </div>
      </div>
    </div>

    <app-form class="mt-8" url="https://www.tinkoff.ru/mobile-operator/iframe/form/for_bot/"/>

    <div class="container">
      <div class="rounded-3xl bg-[#e2e8f0] md:flex justify-between items-end">
        <div class="p-12">
          <h2 class="text-4xl font-normal mb-4">3 месяца Tinkoff&nbsp;Pro<br> за перенос номера</h2>
          <div>Привычный номер с новыми возможностями.<br> Перенесем за 8 дней</div>
          <a href="https://www.tinkoff.ru/mobile-operator/mnp" class="block bg-white px-6 py-4 mt-20 w-fit">Перенести номер</a>
        </div>
        <div class="pt-8 text-right">
          <img src="@/assets/images/pro.webp" alt="">
        </div>
      </div>

      <h2 class="text-center mt-20">Мы продумали все и&nbsp;даже&nbsp;это</h2>
      <div class="md:grid grid-cols-2 gap-12 mt-12">
        <div class="text-center bg-[#f1ebd6] rounded-3xl mb-4 md:mb-0">
          <div class="p-8">
            <h3>Безлимит от Тинькофф</h3>
            <div class="mt-4">Получите безлимитные соцсети, музыку, видео, мессенджеры, 25 ГБ и 600 минут по специальной цене</div>
          </div>
          <img src="@/assets/images/unlim.webp" alt="unlim" class="mx-auto">
        </div>
        <div class="text-center bg-[#e2e8f0] rounded-3xl mb-4 md:mb-0">
          <div class="p-8">
            <h3>Пользуйтесь eSIM сразу</h3>
            <div class="mt-4">Электронная сим-карта уже в вашем телефоне: осталось только активировать ее онлайн за пару кликов</div>
          </div>
          <img src="@/assets/images/esim.webp" alt="unlim" class="mx-auto">
        </div>
        <div class="bg-white rounded-3xl col-span-2 md:flex mb-4 md:mb-0">
          <div class="p-8 md:w-2/3 flex-1">
            <h3>Соберите свой тариф для любых целей</h3>
            <div class="mt-4">
              Выберите удобный пакет минут и гигабайтов, подключите безлимитные соцсети, мессенджеры, музыку и видео.
              Приложения Тинькофф и звонки внутри сети работают бесплатно
            </div>
            <yellow-button class="mt-24" @click="gotoTariffs">Собрать тариф</yellow-button>
          </div>
          <img src="@/assets/images/construct.webp" alt="unlim" class="md:w-5/12 -mt-20">
        </div>
        <div class="bg-white rounded-3xl mb-4 md:mb-0">
          <div class="p-8">
            <img src="@/assets/images/shield.svg" alt="shield" class="mb-8">
            <h3 class="mb-4">Не переживайте о спаме</h3>
            <div class="mb-4">Секретарь Олег ответит на звонки спамеров и мошенников вместо вас. Виртуальный номер можно использовать для регистраций на сайтах, чтобы не раскрывать основной</div>
            <a href="https://www.tinkoff.ru/mobile-operator/features" class="text-blue-700">Узнать об услугах</a>
          </div>
        </div>
        <div class="bg-white rounded-3xl">
          <div class="p-8">
            <img src="@/assets/images/stopwatch.svg" alt="stopwatch" class="mb-8">
            <h3 class="mb-4">Помогаем вам быстро и онлайн</h3>
            <div class="mb-4">Знаем, как неприятно приезжать в офис оператора по пустякам. Поэтому любой вопрос круглосуточно помогут решить в чате приложения или по телефону</div>
          </div>
        </div>
      </div>
      <div class="mt-8 text-center">Тинькофф Мобайл — самый безопасный мобильный оператор <a class="text-blue-700" href="https://telecomdaily.ru/news/2022/02/10/telecomdaily-kto-iz-operatorov-effektivnee-zashchishchaet-ot-telefonnyh-moshennikov">по версии TelecomDaily</a></div>
      <div class="mt-24 pb-32 text-center"><yellow-button @click="gotoForm">Заказать сим-карту</yellow-button><transparent-button class="mt-8 md:mt-0 md:ml-8 border-blue-700 border text-blue-700" @click="gotoTariffs">Посмотреть тарифы</transparent-button></div>
    </div>

  </div>

  <site-footer :phone="phone" disclaimer="" copyright=""></site-footer>
</template>

<script>

import '@/assets/tailwind.css';
import SiteHeader from "@/components/site-header";
import GradientDelimiter from "@/components/gradient-delimiter";
import SiteFooter from "@/components/site-footer-v2";
import YellowButton from "@/components/yellow-button";
import AppForm from "@/components/app-form";
import TransparentButton from "@/components/transparent-button";

export default {
  name: 'App',
  components: {AppForm, YellowButton, TransparentButton, GradientDelimiter, SiteHeader, SiteFooter},

  data: () => ({
    phone: process.env.VUE_APP_PHONE,
  }),

  methods: {
    gotoForm() {
      window.scrollTo({
        top: document.getElementById('form-iframe').offsetTop,
        left: 0,
        behavior: "smooth",
      });
    },

    gotoTariffs() {
      document.location.href = 'https://www.tinkoff.ru/mobile-operator/tariffs';
    }
  }
}

</script>

<style lang="scss">

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans.woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'haas';
  src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("assets/fonts/fa-regular-400.eot");
  src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("assets/fonts/fa-solid-900.eot");
  src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 15px;
  font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  color: #333333;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
}

.container {
  @media screen and (max-width: 575px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.numbers {
  font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 100;
}

h1, h2, h3 {
  font-family: 'TinkoffSans', sans-serif;
  font-weight: 400;
}

h1, h2 {
  font-weight: 700;
  font-variant-ligatures: no-contextual;
  font-size: 44px;
  line-height: 48px;
  letter-spacing: 0;
  @media screen and (max-width: 575px) {
    font-size: 32px;
    line-height: 35px;
  }
}

h2 {
  @media screen and (max-width: 575px) {
    font-size: 28px;
    line-height: 32px;
  }
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

::selection {
  background: rgba(255, 221, 45, .32) !important;
  color: rgba(0, 0, 0, .8) !important;
}

</style>
