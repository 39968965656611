<template>
  <iframe width="100%" height="680" :src="returnUrl" scrolling="no" id="form-iframe"/>
</template>

<script>
export default {
  name: "app-form",
  props: ['url', 'tiktokToken', 'tiktokEvents'],
  computed: {
    returnUrl: function () {
      return this.url + '?parent_url=' + encodeURIComponent(document.location.href);
    }
  },
  created: function () {
    window.addEventListener("message", function (event) {
      if (typeof event.data !== 'string') {
        return;
      }
      var evdata = JSON.parse(event.data);

      if (evdata.type === "appFramePageHeight") {
        var h = evdata.result.height;
        document.getElementById('form-iframe').style.height = (h + 10) + "px";
        return;
      }
      else if (evdata.type === 'analytics') {
        if (evdata.event === 'resultScreenShown') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({'event': 'resultScreenShown', 'result': evdata.result});
        }
      }

    }, false);

    // if (this.tiktokToken !== undefined) {
    //   window.TiktokAnalyticsObject = 'ttq';
    //   var ttq = window['ttq'] = window['ttq'] || [];
    //   ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"], ttq.setAndDefer = function (t, e) {
    //     ttq[e] = function () {
    //       ttq.push([e].concat(Array.prototype.slice.call(arguments, 0)))
    //     }
    //   };
    //   for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
    //   ttq.instance = function (t) {
    //     for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
    //     return e
    //   }, ttq.load = function (e, n) {
    //     var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
    //     ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = i, ttq._t = ttq._t || {}, ttq._t[e] = +new Date, ttq._o = ttq._o || {}, ttq._o[e] = n || {};
    //     var o = document.createElement("script");
    //     o.type = "text/javascript", o.async = !0, o.src = i + "?sdkid=" + e + "&lib=" + 'ttq';
    //     var a = document.getElementsByTagName("script")[0];
    //     a.parentNode.insertBefore(o, a);
    //   };
    //
    //   window.ttq.load(this.tiktokToken);
    //   window.ttq.page();
    //
    //   window.addEventListener('message', (event) => {
    //     let data;
    //     try {
    //       data = JSON.parse(event.data)
    //     } catch (e) {
    //       return;
    //     }
    //
    //     if (data.type === 'analytics') {
    //       this.tiktokEvents.forEach((event) => {
    //         if (event.form === data.event) {
    //           window.ttq.instance(this.tiktokToken).track(event.ttq);
    //         }
    //       });
    //     }
    //   });
    // }
  }
}

</script>

<style scoped>
iframe {
  border: none;
  overflow: hidden;
}
</style>